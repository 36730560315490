import {
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js'
import numeral from 'numeral'
import React from 'react'
import { ChartProps, Line } from 'react-chartjs-2'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
)

const options: ChartProps<'line'>['options'] = {
    events: [],
    responsive: true,
    scales: {
        x: {
            display: true,
            grid: { display: false },
            ticks: {
                autoSkipPadding: 10,
                maxRotation: 0,
                color: '#8983A0',
                font: {
                    family: 'Assistant',
                    size: 14,
                    weight: 'bold',
                },
                stepSize: 10,
            },
        },
        y: {
            grace: 1000,
            display: true,
            grid: {
                display: false,
            },
            ticks: {
                autoSkipPadding: 25,
                color: '#8983A0',
                font: {
                    family: 'Assistant',
                    weight: 'bold',
                    size: 14,
                },
                callback: (value) => {
                    return `€${numeral(value).format('0,0A')}`
                },
            },
        },
    },
    elements: {
        point: {
            radius: 3,
        },
    },
    plugins: {
        legend: {
            display: true,
            position: 'bottom',

            labels: {
                boxWidth: 2,
                color: '#8983A0',
                font: {
                    family: 'Assistant',
                    size: 14,
                    weight: 'bold',
                },
            },
        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
    },
}

export interface LineChartProps {
    cashDataPoints: { year: string; value: number }[]
    investmentDataPoints: { year: string; value: number }[]
}

export const LineChart: React.FC<LineChartProps> = ({
    cashDataPoints,
    investmentDataPoints,
}) => {
    const yearNames = cashDataPoints.map((dataPoint) => dataPoint.year)
    const data = {
        labels: yearNames,
        datasets: [
            {
                label: 'CONTRIBUTIONS',
                data: cashDataPoints.map((dataPoint) => dataPoint.value),
                fill: true,
                backgroundColor: '#fff',
                borderColor: '#EE870E',
            },
            {
                label: '4.5% GROWTH',
                data: investmentDataPoints.map((dataPoint) => dataPoint.value),
                borderColor: '#5261AC',
                backgroundColor: 'rgba(82, 97, 172, 0.3)',
                fill: true,
            },
        ],
    }
    return <Line options={options} data={data} />
}
