import { PensionFooterNotes } from './pensionsFooterNotes'

export const FooterNotesMapper = () => {}

export type TemplateSectionType = 'Pensions' | 'mortgage-protection'

export const footerNotesMapper: { [key: string]: any } = {
    Pensions: PensionFooterNotes,
    Investment: PensionFooterNotes,
}
