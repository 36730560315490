import { Box } from '@chakra-ui/react'
import { HotStepper } from '@irishlifedigitalhub/ds.molecules.hot-stepper'
import React from 'react'
import { useCalculatorMachine } from '../utils/context'
import { ProgressBarStepperStepsType } from '../utils/interfaces'
import { stepperSteps } from '../utils/progressIndicatorSteps'

export interface ProgressIndicatorProps {
    calculatorType: keyof ProgressBarStepperStepsType
}

export const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({
    calculatorType,
}) => {
    const { state } = useCalculatorMachine()
    let currentStep = 1
    switch (true) {
        case (state.context.quoteData as any)?.projection > 0 ||
            state.matches('Quote Screen') ||
            state.matches('Refetching Quote Details'):
            currentStep = stepperSteps[calculatorType].length
            break
        case state.context.screenNo === 0:
            currentStep = 1
            break
        case state.context.screenNo === 1:
            currentStep = 2
            break
        case state.context.screenNo === 2:
            currentStep = 3
            break
        default:
            currentStep = 4
    }

    return (
        <Box id='ProgressIndicator' mb='16px' pb='18px'>
            <HotStepper
                currentStep={currentStep}
                steps={stepperSteps[calculatorType]}
            />
        </Box>
    )
}

export default ProgressIndicator
