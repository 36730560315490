import { Box } from '@chakra-ui/react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import React from 'react'
import { PillarCard, PillarCardProps } from './PillarCard'

export interface PillarsProps {
    title?: string
    subtitle?: string
    pillars: PillarCardProps[]
}

export const Pillars: React.FC<PillarsProps> = ({
    pillars,
    subtitle,
    title,
}) => {
    return (
        <Box flexDirection='column' display='flex'>
            {title && (
                <Text
                    variant='title-md'
                    mb={subtitle ? '6px' : '16px'}
                    color='white'
                    align='center'
                >
                    Get started today
                </Text>
            )}
            {subtitle && (
                <Text variant='body-lg' color='white' align='center' mb='16px'>
                    With a quote or our helpful resources
                </Text>
            )}
            <Box
                display='grid'
                mx='auto'
                gridTemplateColumns={[
                    '1fr',
                    '1fr',
                    '1fr 1fr',
                    '1fr 1fr 1fr 1fr',
                ]}
                maxW='1126px'
                gap='12px'
                flexDirection={['column', 'column', 'column', 'row']}
            >
                {pillars.map((pillar) => (
                    <PillarCard key={pillar.variant} {...pillar} />
                ))}
            </Box>
        </Box>
    )
}
