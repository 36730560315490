import { Box } from '@chakra-ui/react'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'
import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import React from 'react'
import { assumptions } from '../config'

export interface WarningsProps {}

export const Warnings: React.FC<WarningsProps> = () => {
    return (
        <>
            <Box py={'24px'}>
                <Text
                    textAlign={'center'}
                    color={'brand.800'}
                    variant={'title-sm'}
                    pb={'25px'}
                >
                    Two easy ways to start investing
                </Text>
                <Box
                    d='flex'
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='center'
                >
                    <a href='/get-advice'>
                        <Button
                            mb={'28px'}
                            variant='primary'
                            size='lg'
                            mt='12px'
                        >
                            Book an appointment
                        </Button>
                    </a>

                    <a href='/investments/smart-invest/'>
                        <Button
                            mb={'24px'}
                            variant='primary'
                            size='lg'
                            mt='12px'
                        >
                            Get started Online{' '}
                        </Button>
                    </a>
                </Box>
            </Box>
            <Box pb={'12px'}>
                <Text pt={'32px'} pb={'12px'} variant={'title-md'}>
                    Assumptions and other key information:
                </Text>
                <ul>
                    {assumptions.map((item) => (
                        <li key={item}>
                            <Text variant={'body-sm'}>{item}</Text>
                        </li>
                    ))}
                </ul>
            </Box>
        </>
    )
}
