import React from 'react'
import { Stack } from '@chakra-ui/react'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'
import AnimatedNumber from 'react-animated-number'
import numeral from 'numeral'

export interface ResultProps {
    heading: string
    value: number
    term: number
    note: string
    highlighted?: boolean
}
export const Result: React.FC<ResultProps> = ({
    heading,
    value,
    term,
    note,
    highlighted,
}) => {
    return (
        <>
            <Stack align={'center'}>
                <Text
                    color='monochrome.600'
                    variant='caption-xl'
                    sx={{ pb: 4 }}
                >
                    {heading}
                </Text>
                <Text
                    fontWeight={highlighted ? 'bold' : undefined}
                    variant='title-xl'
                    sx={{ pb: 4 }}
                >
                    €
                    <AnimatedNumber
                        component='text'
                        value={value}
                        duration={300}
                        formatValue={(n: any) => numeral(n).format('0,0')}
                    />
                </Text>
                <Text variant='label-md'>
                    Estimated value after {term} years
                </Text>
                <Text color='monochrome.600' variant='label-md' sx={{ pb: 4 }}>
                    {note}
                </Text>
            </Stack>
        </>
    )
}
