export const assumptions = [
    `We have assumed a growth rate of 4.5%. Typically when
    investing over the long term (5 years or more), 4.5% growth
    is likely to require taking a medium level of risk, with
    anything higher requiring a more adventurous outlook, and
    anything lower being more cautious.`,
    `The value of your investment may grow at a faster or slower rate meaning the
    value of your investment may be expected to fall from time
    to time as well as rise.`,
    `The assumed growth rate is net of
    any fees that may apply, e.g. management fees.`,
    `A government insurance levy (currently 1%) applies to
    investments in insurance based investment plans. The
    contributions above are assumed to be net of this levy.`,
    `Exit tax of 41% is payable on investment growth.`,
    `These assumptions and figures quoted are for illustrative purposes
    only.`,
    `Calculations made using this calculator do not
    account for inflation. Inflation may reduce the real returns
    on your investment over time.`,
]
