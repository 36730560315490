import { fireAnalyticsEvent } from 'common/utils/fireAnalyticsEvent'

export const onInteracted = (
    name: string,
    event: string,
    isInteracted: string
) => {
    fireAnalyticsEvent({
        name: name,
        properties: {
            step: event,
            event: name,
            interacted: isInteracted,
        },
    })
}

export const onButtonClicked = (name: string, step: number, event: string) => {
    fireAnalyticsEvent({
        name: name,
        properties: {
            step: (step + 1).toString() + '_' + event,
            event: name,
        },
    })
}

export const onCompleted = (name: string, event: string) => {
    fireAnalyticsEvent({
        name: name,
        properties: {
            event: name,
            step: event,
        },
    })
}

export const handleOtherAnalytics = (
    analyticsName: string,
    formValues: any,
    screenNo: number
) => {
    const calculatorType = analyticsName.split('_')[0]
    if (calculatorType === 'Pensions' && screenNo === 0) {
        fireAnalyticsEvent({
            name: analyticsName,
            properties: {
                event: analyticsName,
                step: 'employment status',
                value: formValues.employmentStatus,
            },
        })
        fireAnalyticsEvent({
            name: analyticsName,
            properties: {
                event: analyticsName,
                step: 'current pension',
                value: formValues.currentPension ?? 'N',
            },
        })
    }
}

export const analyticsService = {
    onInteracted: onInteracted,
    onCompleted: onCompleted,
    onButtonClicked: onButtonClicked,
    handleOtherAnalytics: handleOtherAnalytics,
}
