export const getStyles = ({ isInvalid = false }) => ({
    input: {
        height: 12,
        width: 'full',
        fontSize: 'md',
        bg: 'brand.50',
        rounded: 'md',
        border: 'solid',
        pl: 2,
        pr: 4,
        color: 'var(--chakra-colors-black)',
        _placeholder: {
            color: 'monochrome.400',
        },
        borderColor: isInvalid ? 'error' : 'brand.400',
        borderWidth: isInvalid ? '2px' : '1px',
        '&:focus': {
            borderColor: 'brand.400',
            borderWidth: '2px',
            zIndex: 1,
            boxShadow: '0px 0px 0px 1px #3182ce !important',
        },
        '&:focus-visible': {
            outline: 'none',
        },
    },
    '#email': {
        borderColor: isInvalid ? 'error' : 'brand.400',
        borderWidth: isInvalid ? '2px' : '1px',
    },
    '#currency': {
        paddingLeft: '15px',
    },
    '.PhoneInput': {
        display: 'flex',
        alignItems: 'center',
        height: 12,
        bg: 'brand.200',
        rounded: 'md',
        pl: 3,
        width: 'full',
    },
    '.PhoneInputCountry': {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        width: 7,
    },
    '.PhoneInputCountrySelect': {
        position: 'absolute',
        top: '0',
        left: '0',
        height: 'full',
        width: 'full',
        zIndex: '1',
        border: '0',
        opacity: '0',
        cursor: 'pointer',
    },
    '.PhoneInputCountryIcon': {
        rounded: 'sm',
        overflow: 'hidden',
    },
    '.PhoneInputCountryIconImg': {
        height: 4,
    },
    '.PhoneInputCountryIcon--border': {},
})
