import {
    ShardCard,
    ShardCardProps,
} from '@irishlife/ilgroupdesignsystem.molecules.shard-card'
import React from 'react'
import TagManager from 'react-gtm-module'
import { navigate } from '@reach/router'
import { Box } from '@chakra-ui/react'

export const SharedCard = (props: any[]) => {
    return (props || []).map((sharedCard) => {
        return (
            <Box key={sharedCard.codename}>
                <ShardCard {...getSharedCardProps(sharedCard)} />
            </Box>
        )
    })
}

const getSharedCardProps = (sharedCard: any): ShardCardProps => {
    return {
        subTitle: sharedCard.templatesection___shardcard__title
            ? sharedCard.templatesection___shardcard__title
            : '',
        body: sharedCard.templatesection___shardcard__text,
        callback: () =>
            handleCallBack(sharedCard?.templatesection___shardcard__cta[0]),
        callbackLabel:
            sharedCard?.templatesection___shardcard__cta[0]
                ?.call_to_action__label,
        callbackVariant:
            sharedCard?.templatesection___shardcard__cta[0]
                ?.call_to_action__variant === 'contained'
                ? 'primary'
                : 'secondary',
        callbackSize: 'md',
        image: 'Shared card image',
        imageSrc: sharedCard.templatesection___shardcard__image,
        imageBottom: false,
    }
}

const handleCallBack = (data: any): any => {
    TagManager.dataLayer({
        dataLayer: {
            event: 'primaryButtonClick',
            clickText: data?.call_to_action__label,
            clickURL: data?.call_to_action__url,
            clickID: data?.call_to_action__analytics_id,
        },
    })
    navigate(data?.call_to_action__url || '')
}
