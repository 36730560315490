import { Box, Button, ButtonGroup, IconButton } from '@chakra-ui/react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { MinusIcon, PlusIcon } from '@irishlife/ilgroupdesignsystem.icons'
import numeral from 'numeral'
import React from 'react'
import AnimatedNumber from 'react-animated-number'

export interface AdjustmentControlsProps {
    isDisabled?: boolean
    label: string
    value: number
    onDecrease: () => void
    onIncrease: () => void
    onReset?: () => void
    min?: number
    max?: number
}

export const AdjustmentControls: React.FC<AdjustmentControlsProps> = ({
    isDisabled,
    label,
    value,
    onDecrease,
    onIncrease,
    onReset,
    min,
    max,
}) => {
    return (
        <Box py={'12px'}>
            <Text variant={'body-md'}>{label}</Text>
            <ButtonGroup
                pointerEvents={isDisabled ? 'none' : 'auto'}
                pt={'6px'}
                size='md'
                isAttached
                width={'164px'}
                h={'48px'}
                borderColor='#F1F3FD'
                variant='outline'
            >
                <IconButton
                    backgroundColor='#F1F3FD'
                    color={isDisabled ? 'monochrome.700' : 'vibrant.700'}
                    borderColor='#FFFFFF'
                    disabled={min ? value <= min : false}
                    aria-label='Decrease'
                    icon={<MinusIcon />}
                    onClick={onDecrease}
                >
                    -
                </IconButton>
                <Button
                    color={isDisabled ? 'monochrome.700' : 'vibrant.700'}
                    minW='76px'
                    pointerEvents='none'
                    backgroundColor='#F7F8FB'
                    borderColor='#FFFFFF'
                    onClick={() => onReset?.()}
                >
                    <AnimatedNumber
                        duration={200}
                        formatValue={(value: number) =>
                            numeral(value).format('0')
                        }
                        value={value}
                    />
                </Button>
                <IconButton
                    color={isDisabled ? 'monochrome.700' : 'vibrant.700'}
                    backgroundColor='#F1F3FD'
                    borderColor='#FFFFFF'
                    aria-label='Increase'
                    icon={<PlusIcon />}
                    onClick={onIncrease}
                >
                    +
                </IconButton>
            </ButtonGroup>
        </Box>
    )
}
