import { Stack, useMediaQuery } from '@chakra-ui/react'
import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import {
    ArrowRightCircleIcon,
    ChevronRightIcon,
} from '@irishlife/ilgroupdesignsystem.icons'
import { Card } from '@irishlifedigitalhub/ds.atoms.card'
import { PillarIcon } from '@irishlifedigitalhub/ds.atoms.pillar-icon'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'
import { navigate } from '@reach/router'
import { fireAnalyticsEvent } from 'common/utils/fireAnalyticsEvent'
import React from 'react'

type BorderVariants =
    | 'life'
    | 'investments'
    | 'health'
    | 'pensions'
    | 'wellbeing'

export type PillarCardProps = {
    text: string
    variant: BorderVariants
    primaryCta: {
        label: string
        url: string
        analyticsId: string
    }
    secondaryCta: {
        label: string
        url: string
        analyticsId: string
    }
}

const contentPropsMap = {
    life: {
        text: 'Life Insurance',
    },
    investments: {
        text: 'Investments',
    },
    health: {
        text: 'Health Insurance',
    },
    pensions: {
        text: 'Pensions',
    },
    wellbeing: {
        text: 'Wellbeing',
    },
}

export function PillarCard(props: Readonly<PillarCardProps>): JSX.Element {
    const { variant = 'health', text, primaryCta, secondaryCta } = props
    const { text: pillarName } = contentPropsMap[variant]
    const [isMobileExtraSmall, isMobile] = useMediaQuery([
        '(max-width: 373px)',
        '(max-width: 543px)',
    ])

    return (
        <Card
            data-testid='PillarCard'
            borderTop={variant}
            borderTopWidth={isMobile ? '4px' : '6px'}
            width='full'
            bg={'white'}
            p={
                isMobileExtraSmall
                    ? '16px'
                    : isMobile
                    ? '16px 16px 24px 16px'
                    : '40px 24px 48px 24px'
            }
            outline='none'
            _focus={{ shadow: 'outline' }}
            transition='all 0.15s ease-in-out'
        >
            <Stack
                spacing={'8px'}
                direction={'column'}
                alignItems='flex-start'
                h='100%'
            >
                <Stack
                    pt={0}
                    spacing={isMobileExtraSmall || isMobile ? '7px' : '24px'}
                    direction={
                        isMobileExtraSmall
                            ? 'column'
                            : isMobile
                            ? 'row'
                            : 'column'
                    }
                    alignItems={isMobileExtraSmall ? 'flex-start' : 'center'}
                    width='full'
                >
                    <PillarIcon
                        variant={variant}
                        filled
                        size={isMobile ? 'xsm' : 'sm'}
                    />

                    <Text
                        textAlign={isMobile ? 'start' : 'center'}
                        variant={'title-xs'}
                        color={isMobile ? 'brand.600' : 'brand.900'}
                    >
                        {pillarName}
                    </Text>
                </Stack>
                <Text flex='1'>{text}</Text>
                <Stack
                    direction={['row', 'row', 'row', 'column']}
                    w='full'
                    pt='12px'
                >
                    <Button
                        rightIcon={<ArrowRightCircleIcon />}
                        variant='primary'
                        size='sm'
                        mr='16px'
                        onClick={() => {
                            fireAnalyticsEvent({
                                name: 'secondaryCtaClick',
                                properties: {
                                    clickText: primaryCta.label,
                                    clickURL: primaryCta.url,
                                    clickID: primaryCta.analyticsId,
                                },
                            })
                            navigate(primaryCta.url)
                        }}
                    >
                        {primaryCta.label}
                    </Button>
                    <Button
                        rightIcon={<ChevronRightIcon />}
                        variant='ghost'
                        size='sm'
                        onClick={() => {
                            fireAnalyticsEvent({
                                name: 'secondaryCtaClick',
                                properties: {
                                    clickText: secondaryCta.label,
                                    clickURL: secondaryCta.url,
                                    clickID: secondaryCta.analyticsId,
                                },
                            })
                            navigate(secondaryCta.url)
                        }}
                    >
                        {secondaryCta.label}
                    </Button>
                </Stack>
            </Stack>
        </Card>
    )
}
