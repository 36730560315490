import { Box } from '@chakra-ui/react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { Spinner } from '@irishlifedigitalhub/ds.atoms.spinner'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'

export interface LoadingProps {}

export const Loading: React.FC<LoadingProps> = () => {
    const isDesktop = useDesktop()
    return (
        <Box
            pt={isDesktop ? '66px' : '55px'}
            pb={isDesktop ? '66px' : '55px'}
            w='100%'
            display='flex'
            flexDirection='column'
            alignItems='center'
        >
            <Spinner size='xl' />
            <Text variant='title-xs' color='brand.800' pt='12px'>
                Calculating your pension
            </Text>
            <Text>This may take a few seconds</Text>
        </Box>
    )
}

export default Loading
