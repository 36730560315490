import { Box, Stack } from '@chakra-ui/react'
import { Card } from '@irishlife/ilgroupdesignsystem.atoms.card'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { TickIcon } from '@irishlife/ilgroupdesignsystem.icons'
import React from 'react'
export interface BenefitsCardProps {}

export const BenefitsCard: React.FC<BenefitsCardProps> = () => {
    return (
        <>
            <Box pt={'16px'}>
                <Card px={['24px', '24px']} py={['40px', '40px']}>
                    <Stack>
                        <Text variant={'title-md'} pb={'40px'}>
                            Why choose Irish Life?
                        </Text>

                        <Box display='flex'>
                            <TickIcon color='accent.700' fontSize={24} mr={6} />
                            <Text variant={'body-md'}>
                                Our advisors are on hand to help you and offer
                                guidance.
                            </Text>
                        </Box>
                        <Box display='flex'>
                            <TickIcon color='accent.700' fontSize={24} mr={6} />
                            <Text variant={'body-md'}>
                                We support our customers up until and after
                                retirement.
                            </Text>
                        </Box>
                        <Box display='flex'>
                            <TickIcon color='accent.700' fontSize={24} mr={6} />
                            <Text variant={'body-md'}>
                                Customers who had a financial review with us
                                gave it a score of 94.4%.
                            </Text>
                        </Box>
                    </Stack>
                </Card>
            </Box>
        </>
    )
}
