export const modifySteps: (calculatorSteps: any[]) => {
    cardCaption: string
    buttonCaption: string
    icon: string
    steps: any[]
    info: any
}[] = (calculatorSteps: any[]) => {
    return calculatorSteps?.map((step: any) => {
        return {
            codeName: step?.codename,
            cardCaption: step?.card_caption,
            info: step?.info,
            buttonCaption: step?.button_caption,
            icon: step?.tooltipicon,
            steps: getCalcalutorSteps(step?.steps),
        }
    })
}
const getCalcalutorSteps = (steps: any[]) =>
    steps.map((step) => ({
        codename: step?.codename,
        fieldName: step?.calculatordata__field_name ?? '',
        helpertext: step?.new_helper_text,
        inputtype: step?.input_type,
        isOptional: step?.optional,
        label: step?.label ?? step?.calculatorstepheader__title,
        options: step?.options ?? step?.multiple_choice_options,
        placeholder: step?.placeholder,
        type: step?.type,
        errorMessage: step?.error_message ?? '',
    }))
