import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { Box } from '@chakra-ui/react'
import { useCalculatorMachine } from 'common/components/organisms/Calculator/utils/context'
import React from 'react'
import { Results } from './components/Results'
import { getCalculatorReturns } from './utils/getCalculatorReturns'
import { InvestmentFormType } from 'common/components/organisms/Calculator/utils/interfaces'

export interface InvestmentCalculatorOutputProps {}

export const InvestmentCalculatorOutput: React.FC<InvestmentCalculatorOutputProps> =
    () => {
        const { state } = useCalculatorMachine()
        const calcState = {
            lumpSum: (state.context.formValues as InvestmentFormType)
                .initialAmount,
            regular: (state.context.formValues as InvestmentFormType)
                .monthlyAmount,
            term: (state.context.formValues as InvestmentFormType).noOfYears,
        }
        const calculatorReturns = getCalculatorReturns(calcState)
        return (
            <>
                <Box display={'flex'}>
                    <img
                        style={{ paddingBottom: '46px' }}
                        alt='ProductListCard Icon'
                        src='https://assets-eu-01.kc-usercontent.com/ffd8d21b-ebd4-0151-55d5-297335c8f50f/43627d91-2718-49f2-a757-b7622f9b9004/Bulb%20Orange.svg'
                    />
                    <Text pl={'8px'} paddingBottom={'24px'} variant={'body-md'}>
                        See your potential growth below
                    </Text>
                </Box>
                <Results results={calculatorReturns} term={+calcState.term} />
            </>
        )
    }
