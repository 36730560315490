import React, { useState } from 'react'
import { PhoneNumberInput } from '../inputs/PhoneNumberInput'
import { EmailInput } from '../inputs/EmailInput'
import { CurrencyInput } from '../inputs/CurrencyInput'
import { DefaultInput } from '../inputs/DefaultInput'
import { RichText } from 'common/components/molecules/RichText'
import { CalculatorInputType } from 'common/components/organisms/Calculator/utils/interfaces'

export interface CalculatorStepInputProps {
    register?: () => {}
    placeholder?: string
    label?: string
    fieldName?: string
    inputtype?: string
    control?: any
    errors?: any
    helperText?: string
    errorMessage?: string
    defaultValue?: string
}

export const CalculatorStepInput: React.FC<CalculatorStepInputProps> = ({
    register,
    placeholder,
    label,
    fieldName,
    inputtype,
    control,
    errors,
    helperText,
    errorMessage,
    defaultValue,
}) => {
    const [isTouched, setIsTouched] = useState(false)
    const isInvalid = !!errors[fieldName ?? ''] && isTouched
    const childProps: CalculatorInputType = {
        register,
        placeholder,
        label,
        fieldName,
        inputtype,
        control,
        helperText,
        errorMessage,
        setIsTouched,
        isInvalid,
        defaultValue,
    }
    if (inputtype === 'phone') {
        return (
            <>
                <PhoneNumberInput {...childProps} />
                <HelperText
                    errors={errors}
                    fieldName={fieldName}
                    isTouched={isTouched}
                    helperText={helperText}
                />
            </>
        )
    }
    if (inputtype === 'email') {
        return (
            <>
                <EmailInput {...childProps} />
                <HelperText
                    errors={errors}
                    fieldName={fieldName}
                    isTouched={isTouched}
                    helperText={helperText}
                />
            </>
        )
    }
    if (inputtype === 'currency') {
        return (
            <>
                <CurrencyInput {...childProps} />
                <HelperText
                    errors={errors}
                    fieldName={fieldName}
                    isTouched={isTouched}
                    helperText={helperText}
                />
            </>
        )
    }
    return (
        <>
            <DefaultInput {...childProps} />
            <HelperText
                errors={errors}
                fieldName={fieldName}
                isTouched={isTouched}
                helperText={helperText}
            />
        </>
    )
}

export const HelperText = ({
    errors,
    fieldName,
    isTouched,
    helperText,
}: {
    errors: any
    isTouched: boolean

    fieldName?: string
    helperText?: string
}) => {
    return (
        <>
            {errors[fieldName ?? ''] && isTouched && (
                <p style={{ color: 'var(--chakra-colors-error)' }}>
                    {errors[fieldName ?? ''].message}
                </p>
            )}
            {helperText && (!errors[fieldName ?? ''] || !isTouched) && (
                <RichText label={helperText} html></RichText>
            )}
        </>
    )
}
