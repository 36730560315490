import { Box, Flex } from '@chakra-ui/react'
import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import { Card } from '@irishlife/ilgroupdesignsystem.atoms.card'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { FraudIcon } from '@irishlife/ilgroupdesignsystem.icons'
import { useCalculatorMachine } from 'common/components/organisms/Calculator/utils/context'
import React from 'react'

export const ErrorScreen: React.FC = () => {
    const { send } = useCalculatorMachine()

    const restartQuote = () => {
        send({
            type: 'Calculate Again',
        })
    }

    return (
        <Card>
            <Flex align='start' justify='flex-start' wrap='nowrap'>
                <FraudIcon fontSize={'40px'} color={'#EE870E'} mr={'18px'} />
                <Text variant={'title-sm'}>
                    Sorry, we were unable to complete your quote
                </Text>
            </Flex>

            <Box py={'24px'}>
                <Text pb={'24px'}>
                    We’ve encountered a technical issue. Please return to the
                    first step and try again.
                </Text>

                <Text>
                    Alternatively,
                    <a href={'tel:call (0)1 704 1979'}>
                        <Text as='span' color='brand.500'>
                            {' '}
                            call (0)1 704 1979
                        </Text>
                    </a>{' '}
                    to speak with an Irish Life Financial Services Advisor or
                    contact us via Webchat.
                </Text>
            </Box>
            <Box>
                <Button
                    width='100%'
                    variant={'outline'}
                    size={'md'}
                    mb={'16px'}
                    onClick={restartQuote}
                >
                    Restart quote
                </Button>
                <Button width='100%' variant={'secondary'} size={'md'}>
                    Return to Home
                </Button>
            </Box>
        </Card>
    )
}
